import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-00163876"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "project_list"
};
import TopNav from '@@/rank/nav.vue';
import ProList from '@@/rank/proList.vue';
import { ref, onMounted } from 'vue';
export default {
  setup(__props) {
    onMounted(() => {
      window.userHeader.titleText("全部项目");
      // eslint-disable-next-line no-undef
      userHeader.getMdlink({
        type: 'projectList',
        category: pro_list.value.category,
        status: pro_list.value.status,
        rank: pro_list.value.rank
      });
    });
    const pro_list = ref(null);
    const setRefresh = () => {
      pro_list.value.repoint = true;
    };
    const setCategory = val => {
      pro_list.value.category = val;
      setRefresh();
    };
    const setStatus = val => {
      pro_list.value.status = val;
      setRefresh();
    };
    const setRank = val => {
      pro_list.value.rank = val;
      setRefresh();
    };
    // const onClickLeft = () => {
    //     window.history.go(-1);
    // }

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(TopNav, {
        onSetCategory: setCategory,
        onSetStatus: setStatus,
        onSetRank: setRank
      }), _createVNode(ProList, {
        ref_key: "pro_list",
        ref: pro_list
      }, null, 512)]);
    };
  }
};