export default {
  props: ["show", "title"],
  computed: {
    show_pop() {
      return this.show;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};