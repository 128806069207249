import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1b5ffdd2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "list_sheet"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_action_sheet = _resolveComponent("van-action-sheet");
  return _openBlock(), _createBlock(_component_van_action_sheet, {
    style: {
      width: '750px'
    },
    description: $props.title,
    show: $options.show_pop,
    "onUpdate:show": _cache[0] || (_cache[0] = $event => $options.show_pop = $event),
    "click-overlay": $options.close,
    closeable: false
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])]),
    _: 3
  }, 8, ["description", "show", "click-overlay"]);
}